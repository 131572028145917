import { SVGProps, Ref, forwardRef } from 'react'

const SvgHome = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M22.272 23.247a.98.98 0 0 0 .978-.978V9.747a1.181 1.181 0 0 0-.377-.8L12 .747l-10.873 8.2c-.225.208-.36.495-.377.8v12.522a.98.98 0 0 0 .978.978h20.544Z"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgHome)
export default ForwardRef
